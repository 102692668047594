<template>
  <div class="">
    <v-row>
      
      <v-col class="pa-8" cols="12">
        <v-card
          class="text-center mx-auto pa-6 pb-8"
         flat=""
          max-width="448"
          rounded="lg"
        
        >
        <div class="text-h4 font-weight-bold mb-4">Registe-se</div>
          <div class="text-body-2">E mude a forma como gere as suas páginas</div>
          <v-container>
            <v-text-field
              v-model="nome"
              density="compact"
              color="primary"
              label="Nome"
              variant="outlined"
              :rules="nomeRules"
              hide-details=""
              class="my-3"
            ></v-text-field>

            <v-text-field
              v-model="email"
              density="compact"
              color="primary"
              label="Email"
              variant="outlined"
              hide-details=""
              class="my-3"
              :rules="emailRules"
            ></v-text-field>
            <v-text-field
              v-model="contact"
              density="compact"
              color="primary"
              label="Contacto"
              variant="outlined"
              hide-details=""
              class="my-3"
              :rules="contactRules"
            ></v-text-field>
            <v-text-field
              v-model="website"
              density="compact"
              color="primary"
              label="Link do Website"
              variant="outlined"
              hide-details=""
              class="my-3"
              :rules="websiteRules"
            ></v-text-field>
            <v-select
              label="Selecione a categoria"
              density="compact"
              hide-details=""
              variant="outlined"
              class="my-3"
              :items="[
                'Rent a car',
                'Calçado e Acessórios',
                'Mercearia',
                'Catering',
                'e-learning',
              ]"
              v-model="category"
              :rules="categoryRules"
            ></v-select>
            <v-text-field
              v-model="password"
              :append-inner-icon="visible ? 'mdi-eye-off' : 'mdi-eye'"
              :type="visible ? 'text' : 'password'"
              density="compact"
              color="primary"
              label="Password"
              placeholder="Crie uma palavra-passe"
              variant="outlined"
              hide-details=""
              class="my-3"
              :rules="passwordRules"
              @click:append-inner="visible = !visible"
            ></v-text-field>

            <!-- Removed "Repetir Password" field -->

            <v-checkbox
              v-model="terms"
              color="secondary"
              density="compact"
              label="Concordo com os termos e condições"
              :rules="[termsRule]"
              hide-details=""
            ></v-checkbox>
          </v-container>
          <v-card-actions>
            <v-btn
              @click="registerUser"
              :disabled="!isFormValid"
              block
              color="blue"
              size="large"
              variant="tonal"
            >
              Finalizar registo
            </v-btn>
          </v-card-actions>
          <div class="text-center text-body-2 mb-8">
            Já tenho uma conta
            <router-link to="/login" class="text-blue text-decoration-none">
              Fazer Login
            </router-link>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";

export default {
  data: () => ({
    nome: null,
    email: null,
    contact: null,
    visible: false,
    website: null,
    category: null,
    password: null,
    terms: false,
    nomeRules: [(v) => !!v || "Nome é obrigatório"],
    emailRules: [
      (v) => !!v || "Email é obrigatório",
      (v) => /.+@.+\..+/.test(v) || "Email deve ser válido",
    ],
    contactRules: [], // Add validation rules for contact
    categoryRules: [(v) => !!v || "Categoria é obrigatória"],
    websiteRules: [], // Add validation rules for website
    passwordRules: [
      (v) => !!v || "Palavra-passe é obrigatória",
      (v) =>
        (v && v.length >= 6) ||
        "Palavra-passe deve ter pelo menos 6 caracteres",
    ],
    termsRules: [
      (v) => !!v || "É necessário concordar com os termos e condições",
    ],
  }),

  methods: {
    isFormValid() {
      return (
        this.nomeRules.every((rule) => rule(this.nome)) &&
        this.emailRules.every((rule) => rule(this.email)) &&
        this.contactRules.every((rule) => rule(this.contact)) &&
        this.categoryRules.every((rule) => rule(this.category)) &&
        this.websiteRules.every((rule) => rule(this.website)) &&
        this.passwordRules.every((rule) => rule(this.password)) &&
        this.termsRules.every((rule) => rule(this.terms))
      );
    },

    async registerUser() {
      if (this.isFormValid()) {
        try {
          const response = await axios.post(
            "https://app.brendkit.com/register",
            {
              nome: this.nome,
              email: this.email,
              contact: this.contact,
              website: this.website,
              category: this.category,
              password: this.password,
            }
          );

          console.log(response.data.message);

          // Redirect to the login page or home page as needed
          this.$router.push("/login"); // Assuming you are using Vue Router
        } catch (error) {
          console.error("Error registering user:", error);
          // Handle error and show an appropriate message to the user
        }
      }
    },
  },
};
</script>
<style scoped>
.bg_decor {
  width: 100%;
  height: 100vh;
  background-color: beige;
}
.play {
  width: 100%;
  height: 100%;
  background-color: blue;
}
</style>
