// src/router/index.js
import { createRouter, createWebHistory } from "vue-router";
import ProductUpdate from "@/views/ProductUpdate.vue";
import ProductReservation from "@/views/ProductReservation.vue";
import PageNewsletter from "@/views/PageNewsletter.vue";
import PageForms from "@/views/PageForms.vue";
import LoginPage from "@/views/LoginPage.vue";
import RegisterUser from "@/views/RegisterUser.vue";

const routes = [
  { path: "/product-update", component: ProductUpdate, meta: { requiresAuth: true } },
  { path: "/product-reservation", component: ProductReservation, meta: { requiresAuth: true } },
  { path: "/page-newsletter", component: PageNewsletter, meta: { requiresAuth: true } },
  { path: "/page-forms", component: PageForms, meta: { requiresAuth: true } },
  { path: "/", component: LoginPage },
  { path: "/login", component: LoginPage },
  { path: "/register", component: RegisterUser },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = localStorage.getItem("token");
  if (to.matched.some(record => record.meta.requiresAuth) && !isAuthenticated) {
    next("/login");
  } else {
    next();
  }
});

export default router;
