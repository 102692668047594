<template>
  <v-sheet class="mx-auto">
    <v-slide-group v-model="model" class="pa-4" selected-class="bg-primary">
      <v-slide-group-item
        v-for="n in 2"
        :key="n"
        v-slot="{ toggle, selectedClass }"
      >
        <v-card
          color=""
          :class="['ma-4', selectedClass]"
          height="90"
          variant="outlined"
          width="333.33"
          @click="toggle"
        >
          <v-card-title>{{ Title[n - 1] }}</v-card-title>
          <v-card-text>
            <p>{{ p[n - 1] }}</p>
          </v-card-text>
        </v-card>
      </v-slide-group-item>
    </v-slide-group>

    <v-expand-transition>
      <v-sheet v-if="model != null" height="200">
        <v-card flat>
          <v-card-title class="d-flex align-center pe-2">
            <h4>Tabela de clientes</h4>
            <v-spacer></v-spacer>

            <v-text-field
              v-model="search"
              prepend-inner-icon="mdi-magnify"
              density="compact"
              label="Search"
              single-line
              flat
              hide-details
              variant="solo-filled"
            ></v-text-field>
          </v-card-title>

          <v-divider></v-divider>

          <v-data-table
            :items="getTableData"
            :search="search"
            class="elevation-1"
          >
            <!-- Customize the slots for each column -->
            <template v-slot:[`item.image`]="{ item }">
              <v-img :src="item.image" height="64" cover></v-img>
            </template>

            <template v-slot:[`item.rating`]="{ item }">
              <v-rating
                :model-value="item.rating"
                color="orange-darken-2"
                density="compact"
                size="small"
                readonly
              ></v-rating>
            </template>

            <template v-slot:[`item.status`]="{ item }">
              <v-chip
                :color="item.status ? 'green' : 'yellow'"
                :text="item.status ? 'Urgente' : 'Normal'"
                class="text-uppercase"
                label
                size="small"
              ></v-chip>
            </template>

            <template v-slot:[`item.action`]="{ item }">
              <div class="text-center">
                <v-btn variant="text" icon size="small" @click="viewItem(item)">
                  <v-icon>mdi-eye</v-icon>
                </v-btn>
                <v-btn
                  variant="text"
                  icon
                  size="small"
                  @click="deleteItem(item)"
                >
                  <v-icon>mdi-delete</v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </v-card>
      </v-sheet>
    </v-expand-transition>
  </v-sheet>
</template>

<script>
export default {
  data: () => ({
    reservas: [],
    rentCars: [], // Add arrays for other collections
    mercearias: [],
    caterings: [],
    model: null,
    Title: ["Reservas", "Subscritos", "Mercearia", "Catering"],
    p: [
      "Clique aqui para Visualizar",
      "This form was created to help ",
      "This form was created to help ",
      "This form was created to help ",
    ],
    search: "",
  }),
  computed: {
    getTableData() {
      switch (this.model) {
        case 0:
          return this.reservas;
        case 1:
          return this.rentCars;
        case 2:
          return this.mercearias;
        case 3:
          return this.caterings;
        default:
          return [];
      }
    },
  },
  watch: {
    model: "loadData",
  },
  methods: {
    loadData() {
      switch (this.model) {
        case 0:
          this.fetchReservasData();
          break;
        case 1:
          this.fetchRentCarsData();
          break;
        case 2:
          this.fetchMerceariasData();
          break;
        case 3:
          this.fetchCateringsData();
          break;
        default:
          break;
      }
    },
    // Add similar methods for other collections (rent-cars, mercearias, caterings)

    // ... other methods ...

    async fetchReservasData() {
      try {
        const response = await fetch("https://app.brendkit.com/reservas");

        // Check if the response status is OK (200)
        if (!response.ok) {
          throw new Error(
            `Error fetching data from reservas. Status: ${response.status}`
          );
        }

        // Check if the response content type is JSON
        const contentType = response.headers.get("content-type");
        if (!contentType || !contentType.includes("application/json")) {
          throw new Error("Invalid content type. Expected JSON.");
        }

        const data = await response.json();

        // Assuming your "reservas" collection has similar fields as the example below
        this.reservas = data.map((item) => ({
          Cliente: item.nomeCompleto,
          Contacto: item.telefone,
          Email: item.email,
          Localizacao: item.location,
          Producto: item.productName,
          Tamanho: item.productSize,
          Preço: item.productPrice,
          Dia: item.createdAt, // You may need to format this based on your data structure
          status: true, // Modify based on your data structure
          image: item.imageUrl, // Replace with your image field
          action: "", // You may need to add logic for actions
        }));
      } catch (error) {
        console.error(error.message);
      }
    },

    async fetchMerceariasData() {
      try {
        const response = await fetch("https://app.brendkit.com/mercearias");
        const merceariasData = await response.json();
        // Process data and assign it to this.mercearias
        this.mercearias = merceariasData; // Use the data or process it as needed
      } catch (error) {
        console.error("Error fetching data from mercearias:", error);
      }
    },

    async fetchCateringsData() {
      try {
        const response = await fetch("https://app.brendkit.com/caterings");
        const cateringsData = await response.json();
        // Process data and assign it to this.caterings
        this.caterings = cateringsData; // Use the data or process it as needed
      } catch (error) {
        console.error("Error fetching data from caterings:", error);
      }
    },
    async fetchRentCarsData() {
      try {
        const response = await fetch("https://app.brendkit.com/rent-cars");
        const rentCarsData = await response.json();
        // Process data and assign it to this.caterings
        this.rentCars = rentCarsData; // Use the data or process it as needed
      } catch (error) {
        console.error("Error fetching data from rent-cars:", error);
      }
    },

    // ... other methods ...
  },
};
</script>
